import React from "react";
import '../assets/styles/sections/Header.css';

const MENU_ITEMS = [
    { 
        title: "About Me", 
        href: "#about_me_section"
    },
    { 
        title: "Skills", 
        href: "#skills_section" 
    },
    { 
        title: "Soft Skills", 
        href: "#soft_skills_section" 
    },
    { 
        title: "Education", 
        href: "#education_section" 
    },
    { 
        title: "Projects", 
        href: "#projects_section" 
    }
];

const Header = () => {
    return (
        <header className="header_container">
            <h1 className="icon_name"><strong className="name_title">&lt;</strong>Cristian Gallegos<strong className="name_title">/&gt;</strong></h1>
            <nav className="header_nav">
                {
                    MENU_ITEMS.map((menuItem, index) => {
                        return (
                            <a key={index} className="headerPrincipal_nav_item header_item_desktop" href={menuItem.href}>{menuItem.title}</a>
                        );
                    })
                }
                <address>
                    <a className="headerPrincipal_nav_item item_email" href="mailto:cristianga2002@ciencias.unam.mx">cristianga2002@ciencias.unam.mx</a>
                </address>
            </nav>
        </header>
    );
};

export default Header;