import React from "react";
import "../assets/styles/sections/Footer.css";

const Footer = () => {
    return (
        <footer className="footer_container">
            <h6 className="footer_text">Designed, Built & Made with <span className="animated_emoji">💙</span> by Cristian Gallegos</h6>
            <h6 className="footer_text">Copyright © 2024 | All Rights Reserved</h6>
        </footer>
    );
};

export default Footer;