import React, {
    useEffect,
    useState
} from "react";
import TextField from "@mui/material/TextField";
import AlertMC from "./MuiCore/AlertMC";
import BackDrop from "./MuiCore/BackDrop";
import DialogResponsive from "./MuiCore/DialogResponsive";
import {
    isWithinLimitMaxSpace,
    isWithinLimitMinSpace,
    isValidName,
    isValidEmail,
    isCorrectEmail,
    isValidMessage
} from "../utils/Validations";
import TruckStatic from "../assets/images/truck.svg";
import TruckMoving from "../assets/images/truck.gif";
import "../assets/styles/components/Form.css";

const FormPresentation = () => {
    return (
        <div className="form_presentation_container">
            <h2>Spacecraft Transmission Center</h2>
            <p>Do you have any project in mind or simply do you want to chat? I'll be happy to listen to you!</p>
        </div>
    );
};

const Form = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isSending, setIsSending] = useState(false);
    const [openDialogMail, setOpenDialogMail] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);

    const [amISureToSendMail, setAmISureToSendMail] = useState(false);

    const [alertTitle, setAlertTitle] = useState('');
    const [alertDescription, setAlertDescription] = useState('');
    const [alertSeverity, setAlertSeverity] = useState('');

    const [errorName, setErrorName] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const [helperTextMessage, setHelperTextMessage] = useState('Enter your message.');
    const [Truck, setTruck] = useState(TruckStatic);

    const updateName = (value) => {
        if (!isValidName(value)) { return; }
        if (errorName) { setErrorName(false); }
        setName(value);
    };

    const updateMail = (value) => {
        if (!isValidEmail(value)) { return; }
        if (errorEmail) { setErrorEmail(false); }
        setEmail(value);
    };

    const updateMessage = (value) => {
        if (!isValidMessage(value) || !isWithinLimitMaxSpace(value, 500)) { return; }
        if (errorMessage) { setErrorMessage(false); }
        setMessage(value);
        if (value === '' || value.length < 3) {
            setHelperTextMessage('Enter your message.');
        } else {
            setHelperTextMessage(value.length + '/500 characters');
        }
    };

    useEffect(() => {
        if (name !== '' && email !== '' && message !== '') {
            setTruck(TruckMoving);
        }
    }, [name, email, message]);
    
    const sendEmailVerification = async (event) => {
        event.preventDefault();
        if (name === '' || !isWithinLimitMinSpace(name, 3)) {
            setErrorName(true);
            return;
        }
        if (email === '' || !isCorrectEmail(email)) {
            setErrorEmail(true);
            return;
        }
        if (message === '' || !isWithinLimitMinSpace(message, 3)) {
            setErrorMessage(true);
            return;
        }
        setOpenDialogMail(true);
    };
    
    useEffect(() => {
        if (!amISureToSendMail) { return; }
        setIsSending(true);
        const sendEmail = async () => {
            try {
                const response = await fetch('https://polar-byte.vercel.app/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: name,
                        email: email,
                        message: message
                    })
                });
                await response.json();
                if (response.status !== 200) {
                    setAlertTitle('Houston, we have a problem!');
                    setAlertDescription("It seems like the message escaped the spacecraft. It couldn't be sent this time. Please try again later.");
                    setAlertSeverity('error');
                    return;
                }
                setAlertTitle('Mission accomplished!');
                setAlertDescription('Stay tuned for updates.');
                setAlertSeverity('success');
                setName('');
                setEmail('');
                setMessage('');
                setTruck(TruckStatic);
            } catch (error) {
                setAlertTitle('Watch out for asteroids!');
                setAlertDescription('Our server is dodging technical issues. Please try sending your message again later.');
                setAlertSeverity('error');
            } finally {
                setOpenAlert(true);
                setIsSending(false);
                setAmISureToSendMail(false);
            }
        };
        sendEmail();
    }, [amISureToSendMail, name, email, message]);

    return (
        <>
            <form
                onSubmit={sendEmailVerification}
                className="form_container"
            >
                <FormPresentation />
                <div>
                    <TextField
                        id="name"
                        label="Full Name"
                        helperText="Enter your full name."
                        value={name}
                        onChange={(e) => updateName(e.target.value)}
                        fullWidth
                        error={errorName}
                    />
                </div>
                <div>
                    <TextField
                        id="email"
                        label="Email Address"
                        helperText="Enter your email address."
                        value={email}
                        onChange={(e) => updateMail(e.target.value)}
                        fullWidth
                        error={errorEmail}
                    />
                </div>
                <div>
                    <TextField
                        id="message"
                        label="Message"
                        helperText={helperTextMessage}
                        value={message}
                        onChange={(e) => updateMessage(e.target.value)}
                        fullWidth
                        multiline
                        rows={8}
                        error={errorMessage}
                    />
                </div>
                <button
                    type="submit"
                    className="submit_button"
                >
                    Submit
                    <img src={Truck} alt="Truck"/>
                </button>
            </form>
            {
                openDialogMail &&
                (
                    <DialogResponsive 
                        openDialog={openDialogMail}
                        setOpenDialog={setOpenDialogMail}
                        idDialog="dialog-mail"
                        dialogTitle="Are you sure you want to send this message to infinity and beyond?"
                        descriptionText="Your message is about to embark on a stellar journey through the digital cosmos. Ready to watch it shine in the darkness?"
                        textCancelButton="Maybe another time..."
                        textConfirmButton="Yes, launch to infinity!"
                        setIsSuccess={setAmISureToSendMail}
                    />
                )
            }
            {
                isSending && 
                (
                    <BackDrop />
                )
            }
            {
                <AlertMC
                    openAlert={openAlert}
                    setOpenAlert={setOpenAlert}
                    alertTitle={alertTitle}
                    alertDescription={alertDescription}
                    alertSeverity={alertSeverity}
                    setAlertTitle={setAlertTitle}
                    setAlertDescription={setAlertDescription}
                    setAlertSeverity={setAlertSeverity}
                />
            }
        </>
    );
};

export default Form;