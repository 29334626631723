import React, {
    useEffect,
    useState
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SkillsAndToolsDB from "../database/skills-and-tools.json";
import "../assets/styles/sections/SkillsAndTools.css";

const SKILLS_AND_TOOLS = SkillsAndToolsDB;

const SkillsAndTools = () => {
    const [animations, setAnimations] = useState(new Array(SKILLS_AND_TOOLS.length).fill(false));
    
    useEffect(() => {
        const intervalAnimations = setInterval(() => {
            let newAnimations = new Array(SKILLS_AND_TOOLS.length).fill(false);
            for (let i = 0; i < newAnimations.length; i++) {
                let valueRandom = Math.round(Math.random());
                if (valueRandom === 1) {
                    newAnimations[i] = true;
                }
            }
            setAnimations(newAnimations);
        }, 2000);
        return () => {
            clearInterval(intervalAnimations);
        }
    }, []);

    return (
        <section
            id="skills_section"
            className="skills_hobbies_container"
        >
            <div className="skills_title">
                <h2>Skills & Tools</h2>
                <div className="line"></div>
            </div>
            <div className="skills_container">
                {
                    SKILLS_AND_TOOLS.map((skillAndTool, index) => {
                        const isAnimatedActive = animations[index] ? "_animated_active" : "";
                        return (
                            <div 
                                key={index}
                                className={`skills_item ${skillAndTool.style} ${skillAndTool.style + isAnimatedActive} ${isAnimatedActive}`}
                            >
                                <div className="svg_skill_container">
                                    <FontAwesomeIcon 
                                        icon={skillAndTool.icon}
                                    />
                                </div>
                                <div className="skills_and_tool_title">
                                    <h4>{skillAndTool.name}</h4>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
};

export default SkillsAndTools;