import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faFile,
    faHashtag,
    faDatabase,
    faRepublican,
    faMicrochip,
    faFlag,
    faPalette,
    faLightbulb,
    faStopwatch,
    faPersonRunning,
    faSitemap,
    faThumbTack,
    faUsersGear,
    faPuzzlePiece,
    faLeaf,
    faWind,
    faHatCowboy
} from '@fortawesome/free-solid-svg-icons';
import {
    faLinkedin,
    faGithub,
    faInstagram,
    faGoogle,
    faFigma,
    faHtml5,
    faCss3Alt,
    faJsSquare,
    faReact,
    faAndroid,
    faJava,
    faPython,
    faSquareGithub,
    faSquareGitlab,
    faDocker,
    faLinux,
    faUnity,
    faNode
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faLinkedin,
    faGithub,
    faInstagram,
    faFile,
    faGoogle,
    faFigma,
    faHtml5,
    faCss3Alt,
    faJsSquare,
    faReact,
    faAndroid,
    faJava,
    faPython,
    faHashtag,
    faDatabase,
    faRepublican,
    faSquareGithub,
    faSquareGitlab,
    faDocker,
    faLinux,
    faUnity,
    faMicrochip,
    faFlag,
    faPalette,
    faLightbulb,
    faStopwatch,
    faPersonRunning,
    faSitemap,
    faThumbTack,
    faUsersGear,
    faPuzzlePiece,
    faLeaf,
    faWind,
    faHatCowboy,
    faNode
);