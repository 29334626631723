import React from "react";
import ProjectsDB from "../database/projects.json";
import "../assets/styles/sections/Projects.css";

const PROJECTS = ProjectsDB;

const Projects = () => {
    return (
        <div
            id="projects_section"
            className="projects_container"
        >
            <div className="projects_title_container">
                <h2>Projects</h2>
                <div className="line"></div>
            </div>
            <div className="projects_card_container">
                {
                    PROJECTS.map((project, index) => {
                        return (
                            <div className="card_project" key={index}>
                                <img className="image_project" src={process.env.PUBLIC_URL + project.src} alt={project.alt} />
                                <div className="card_project_body">
                                    <h2 className="card_title_project">{project.title}</h2>
                                    <p className="card_information_project">{project.information}</p>
                                    <div className="buttons_card_container">
                                        <a 
                                            href={project.page}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button className="card_page_button">View page</button>
                                        </a>
                                        <a 
                                            href={project.repository}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <button className="card_repository_button">View Repository</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Projects;