import React, {
    useState,
    useEffect
} from "react";
import { Fade } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Snackbar from "@mui/material/Snackbar";

const getAnchorOrigin = () => {
    if (window.innerWidth <= 1024) { 
        return { vertical: 'bottom', horizontal: 'center' };
    }
    return { vertical: 'bottom', horizontal: 'right' };
};

const AlertMC = (props) => {
    const {
        openAlert,
        setOpenAlert,
        alertTitle,
        alertDescription,
        alertSeverity,
        setAlertTitle,
        setAlertDescription,
        setAlertSeverity
    } = props;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {return;}
        setOpenAlert(false);
        setAlertTitle('');
        setAlertDescription('');
        setAlertSeverity('');
    };

    const [anchorOrigin, setAnchorOrigin] = useState(getAnchorOrigin());
    useEffect(() => {
        const handleResize = () => {
            setAnchorOrigin(getAnchorOrigin());
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Snackbar 
            open={openAlert}
            TransitionComponent={Fade}
            anchorOrigin={anchorOrigin}
        >
            <Alert
                severity={alertSeverity}
                sx={{ width: '100%' }}
                onClose={handleClose}
            >
                <AlertTitle>{alertTitle}</AlertTitle>
                {alertDescription}
            </Alert>
        </Snackbar>
    );
};

export default AlertMC;