import React, { 
    Fragment 
} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const DialogResponsive = (props) => {
    const {
        setOpenDialog,
        idDialog,
        dialogTitle,
        descriptionText,
        textCancelButton,
        textConfirmButton,
        setIsSuccess
    } = props;
    return (
        <Fragment>
            <Dialog
                open={true}
                aria-labelledby={`${idDialog}-alert-dialog-title`}
                aria-describedby={`${idDialog}-alert-dialog-description`}
            >
                <DialogTitle id={`${idDialog}-alert-dialog-title`}>
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id={`${idDialog}-alert-dialog-description`}>
                        {descriptionText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={() => {
                            setOpenDialog(false);
                        }}
                        color="error"
                    >
                        {textCancelButton}
                    </Button>
                    <Button 
                        onClick={() => {
                            setOpenDialog(false);
                            setIsSuccess(true);
                        }} 
                        variant="contained"
                        color="success"
                        autoFocus
                    >
                        {textConfirmButton}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export default DialogResponsive;