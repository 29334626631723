import React, {
    useEffect,
    useState
} from "react";
import { 
    Swiper, 
    SwiperSlide 
} from "swiper/react";
import {
    Autoplay,
    FreeMode, 
    Navigation, 
    Pagination 
} from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SoftSkillsDB from "../database/soft-skills.json"; 
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "../assets/styles/sections/SoftSkills.css";

const SOFT_SKILLS = SoftSkillsDB;

function calculateSlidesPerView(windowWidth) {
    if (windowWidth >= 1024) {
        return 3;
    }
    return 1;
}

const SoftSkills = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [numberOfSlides, setNumberOfSlides] = useState(calculateSlidesPerView(windowWidth));

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setNumberOfSlides(calculateSlidesPerView(window.innerWidth));
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div
            id="soft_skills_section"
            className="soft_skills_container"
        >
            <div>
                <h2 className="soft_skills_title">Soft Skills</h2>
                <div className="line"></div>
            </div>
            <div>
                <Swiper
                    slidesPerView={numberOfSlides}
                    spaceBetween={50}
                    freeMode={true}
                    pagination={{
                        clickable: true
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    navigation={true}
                    loop={true}
                    modules={[Autoplay, FreeMode, Navigation, Pagination]}
                    className="mySwiper"
                >
                    {
                        SOFT_SKILLS.map((skill, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <div className="soft_skill_card">
                                        <FontAwesomeIcon icon={skill.icon} />
                                        <h3>{skill.title}</h3>
                                    </div>
                                </SwiperSlide>
                            );    
                        })
                    }
                </Swiper>
            </div>
        </div>
    );
};

export default SoftSkills;