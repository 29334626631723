import React from "react";
import "../assets/styles/sections/AboutMe.css";

const AboutMe = () => {
    return (
        <section 
            id="about_me_section"
            className="about_me_container"
        >
            <h2 className="title_about">About Me</h2>
            <p className="information_about">
                I'm Cristian, a 22-year-old computer scientist, exploring the vast oceans of the digital world. With a restless mind and a heart full of passion for technology, I embark on a journey towards innovation and creativity.
            </p>
            <p className="information_about">
                Although I'm just taking the first steps on this journey, I've already had the opportunity to dive into the world of Front-End Development at Alura Latam, thanks to the ONE Oracle Next Education program. There, I discovered the art of bringing digital interfaces to life and captivating users with dazzling and exciting experiences.
            </p>
            <p className="information_about">
                I dive into an ocean of ideas, experiments, and projects, always eager to discover the next horizon to explore. The journey is just beginning, and I'm excited for what the future holds!
            </p>
        </section>
    );
};

export default AboutMe;