import React from "react";
import Form from "../components/Form";
import Envelope from "../assets/images/envelope.png";
import "../assets/styles/sections/ContactMeForm.css";

const ContactMeForm = () => {
    return (
        <section className="contact_me_form">
            <img
                className="envelope_container"
                src={Envelope}
                alt="Envelope"
            />
            <Form />
        </section>
    );
};

export default ContactMeForm;