import React from "react";
import ParticlesComponent from "./components/ParticlesComponent";
import Header from "./sections/Header";
import Presentation from "./sections/Presentation";
import AboutMe from "./sections/AboutMe";
import SkillsAndTools from "./sections/SkillsAndTools";
import SoftSkills from "./sections/SoftSkills";
import Education from "./sections/Education";
import Projects from "./sections/Projects";
import Contact from "./sections/Contact";
import ContactMeForm from "./sections/ContactMeForm";
import Footer from "./sections/Footer";
import "./assets/fontawesome";
import "./assets/styles/reset.css";
import "./assets/styles/App.css";
import "./assets/styles/animations.css";

function App() {
  return (
    <div>
      <ParticlesComponent 
        id="particles"
      />
      <div>
        <Header />
        <main>
          <Presentation />
          <AboutMe />
          <SkillsAndTools />
          <SoftSkills />
          <Education />
          <Projects />
          <Contact />
          <ContactMeForm />
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default App;