import React, {
    useEffect
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PresentationDB from "../database/presentation.json";
import "../assets/styles/sections/Presentation.css";

const SOCIAL_MEDIA = PresentationDB;

const Presentation = () => {
    useEffect(() => {
        const texts = ["Exploring the frontiers of computational science and front-end development...",
            "Building the bridge between creativity."];
        const typedTextElement = document.getElementById("typewriter");
        let currentTextIndex = 0;

        function typeText(text) {
            let currentCharIndex = 0;
            const typingAnimation = setInterval(() => {
                typedTextElement.textContent = text.slice(0, currentCharIndex);
                currentCharIndex++;
                if (currentCharIndex > text.length) {
                    clearInterval(typingAnimation);
                    setTimeout(() => {
                        typedTextElement.textContent = "";
                        nextWord();
                    }, 1000);
                }
            }, 100);
        }

        function nextWord () {
            const indexWord = texts[currentTextIndex];
            currentTextIndex = (currentTextIndex + 1) % texts.length;
            typeText(indexWord);
        }

        nextWord();
        return () => clearInterval();
    }, []);
    return (
        <section className="presentation_container" >
            <h5 className="my_intro">¡Hi! my name is</h5>
            <h1 className="my_name">Cristian Gallegos</h1>
            <h1 className="extra_information" id="typewriter">Exploring the frontiers of computational science and front-end development...</h1>
            <p className="short_information">
                I'm a student at the Faculty of Sciences, UNAM. I blend science and creativity to turn bits and bytes into digital masterpieces.
            </p>
            <nav>
                <ul className="presentation_nav_container">
                    {
                        SOCIAL_MEDIA.map((socialMedia, index) => {
                            return (
                                <li key={index}>
                                    <a 
                                        className={`presentation_nav_item ${socialMedia.className}`} 
                                        href={socialMedia.href} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon 
                                            icon={socialMedia.icon}
                                            className={socialMedia.className}
                                        />
                                        <h4 className={`${socialMedia.socialMedia}_item`}>{socialMedia.name}</h4>
                                    </a>
                                </li>
                            );
                        })
                    }
                </ul>
            </nav>
        </section>
    );
};

export default Presentation;