import React from "react";
import EducationDB from "../database/education.json";
import "../assets/styles/sections/Education.css";

const ITEMS_EDUCATION = EducationDB;

const Education = () => {
    return (
        <div
            id="education_section"
            className="education_container"
        >
            <div className="title_education_container">
                <h2>Education</h2>
                <div className="line"></div>
            </div>
            <ul className="education_items_container">
                {
                    ITEMS_EDUCATION.map((item, index) => {
                        return (
                            <li key={index}>
                                <img src={process.env.PUBLIC_URL + item.src} alt={item.alt} />
                                <h3>{item.title}</h3>
                                <p>{item.information}</p>
                            </li>
                        );
                    })
                }
            </ul>
        </div>
    );
};

export default Education;