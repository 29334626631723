import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactDB from "../database/contact.json";
import Me from "../assets/images/me.png"
import "../assets/styles/sections/Contact.css";

const CONTACT_ME_LINKS = ContactDB;

const Contact = () => {
    return (
        <section className="contact">
            <div className="contact_container">
                <div className="information_contact_container">
                    <h2 className="first_text_contact">Don't be a stranger!</h2>
                    <button className="button_contact_me">
                        <a className="text_button" href="mailto:cristianga2002@ciencias.unam.mx">Connect with me online</a>
                    </button>
                    <nav className="social_media_contact">
                        {
                            CONTACT_ME_LINKS.map((link, index) => {
                                return (
                                    <a 
                                        key={index} 
                                        className="social_media_item_contact" 
                                        href={link.href} 
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FontAwesomeIcon
                                            icon={link.icon}
                                            className={link.icon} 
                                        />
                                    </a>
                                );
                            })
                        }
                    </nav>
                    <h1 className="third_text_contact">Hey there!</h1>
                </div>
                <img className="me_img" src={Me} alt="Me" />
            </div>
        </section>
    );
};

export default Contact;